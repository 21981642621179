.swipe-root {
    display: flex;
    justify-content: space-between;
}

.swipe-left-pad, .swipe-right-pad {
    width: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swipe-child {
    width: 100%;
}