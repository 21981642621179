.conble-actions {
    padding: 2vw 0 2vw 0;
    user-select: none;
}

.conble-action {
    display: flex;
    align-items: center;

    text-decoration: none;
    color: black;
    font-size: 4vw;
    padding-left: 2vw;

    user-select: none;
}

.conble-action-icon {
    margin-right: 2vw;
}

.conble-sep {
    margin: 3vw 0 3vw 0;
}

@media (min-width:700px) and (min-aspect-ratio: 1){
    .conble-actions {
        padding: .1vw 0 .1vw 0;
    }
    
    .conble-action {
        font-size: 1.5vw;
        padding-left: 1vw;
    }
    
    .conble-action-icon {
        margin-right: 1vw;
    }

    .conble-sep {
        margin: 1.5vw 0 1.5vw 0;
    }
}

@media (min-width:700px) and (min-aspect-ratio: 1.35){
    .conble-actions {
        padding: .1vw 0 .1vw 0;
    }
    
    .conble-action {
        font-size: 1.5vw;
        padding-left: .5vw;
    }
    
    .conble-action-icon {
        margin-right: .5vw;
    }

    .conble-sep {
        margin: 1vw 0 1vw 0;
    }
}
