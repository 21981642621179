.lv-tab-main {
    display: flex;
    flex-direction: column;
    align-items: start;
    max-height: 87vh;
    overflow-y: scroll;
}

.lv-sort-main {
    display: flex;
    align-items: center;
    margin: 2vw 0vw 2vw 3vw;
    cursor: pointer;
}

.lv-sort-tick {
    margin-right: 2vw;
}

.lv-search-main {
    display: flex;
    align-items: center;
}

.lv-sort-icon {
    margin-left: 2vw;
}

.lv-res-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.lv-res-count {
    margin: 0.5vw 2vw 0.5vw 2vw;
    color: #888;
    display: flex;
    align-items: center;
}

.lv-sort-clear {
    background-color: var(--bar);
    color: white;
    padding: 1vw;
    border-radius: 1vw;
    font-size: 3vw;
    cursor: pointer;
    margin: 0 1vw 0 1vw;
}

.lv-no {
    width: 100%;
    display: flex;
    margin: 4vw 0 4vw 0;
    justify-content: center;
}

.lv-more {
    display: flex;
    align-items: center;
    background-color: var(--surface);
    color: white;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
    padding: 1vw;
    user-select: none;
    cursor: pointer;
}