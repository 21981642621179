:root {
  --bar: #00415D;
  --surface: #0075a7;
  --selected: #6fa1d2;
  --bg: #fff;
  --secondaryBg: #1f2c34;
  --toastBg: #6d6c6c71;
  --green: #25d366;
  --warning: rgba(255, 85, 85, 0.75);

  --breakwidth: 700px;
  --tablet: 1;
  --desktop: 1.35;
}

body {
  background-color: var(--bg);
  max-height: 100vw;
  overflow: hidden;
}

* {
  font-size: 3.5vw;
}

.bold {
  font-weight: bold;
}

.light {
  color: #555;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-end {
  justify-content: end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-align-center {
  align-items: center;
}

.flex-end {
  align-items: flex-end;
}