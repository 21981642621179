.dropdown-root {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    border: none;
    border-radius: 1vw;
    align-items: center;
}

.dropdown-field {
    padding: 1.5vw;
    outline: none;
    border: none;
    border-radius: 1vw;
    font-size: 4vw;
    width: 90%;
    height: 4.5vw;
    cursor: pointer;
}

.dropdown-icon {
    margin: 0vw 1vw 0 0;
}

.dropdown-view-only {
    font-size: 4vw;
    font-weight: 900;
}

@keyframes dropdown-zoomin-close {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
    .dropdown-root {
        border-radius: 0.5vw;
    }

    .dropdown-field {
        padding: .5vw;
        border-radius: 0.5vw;
        font-size: 1.1vw;
        height: 1.5vw;
    }
}