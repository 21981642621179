.users-filter {
    border: none;
    outline: none;

    width: 94vw;
    font-size: 4vw;
    padding: 3vw;

}

.users-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;

    max-height: 94.5vh;
    overflow-y: scroll;
}

.users-actions {
    padding: 2vw;
}

.users-action {
    cursor: pointer;
}

