.const-text {
    width: 80%;
    font-size: 4vw;
    text-align: center;
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
    .const-text {
        width: 100%;
        font-size: 1.1vw;
    }   
}