.seva-main {
    padding: 1vw 2vw 1vw 2vw;
    border-bottom: 0.1vw #aaa solid;
    width: 100vw;
    box-sizing: border-box;
}

.seva-name {
    font-size: 4vw;
    font-weight: bold;
}

.seva-date {
    font-size: 3.75vw;
    color: #555;
}

.seva-createdOn {
    color: #555;
    font-size: 3vw;
}

.seva-del {
    background-color: rgb(255, 42, 42);
    color: white;
}