.protect-cont {
    display: flex;
    height: 80vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.protect-text {
    font-size: 4.5vw;
    width: 80%;
    text-align: center;
    margin-top: 2vw;
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
    .protect-text {
        font-size: 1.5vw;
    }
}