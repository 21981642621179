.install-root {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;

    align-items: center;
}

.install-logo {
    height: 40vh;
    display: flex;
    align-items: center;
}

.install-loader {
    height: 10vh;
}

.install-body {
    height: 50vh;
    display: flex;
    align-items: center;
    flex-direction: column;

}

#install-button {
    display: flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background: var(--bar);
    color: #fff;
    font-size: 5vw;
    padding: 2vw;
    border-radius: 1vw;
    width: 80vw;
    justify-content: center;
    margin-bottom: 2vh;
    margin-top: 3vh;
    border: none;
}

.install-timeout {
    color: #222;
    width: 85vw;
    font-size: 4vw;
}

.install-installed {
    color: #222;
    width: 85vw;
    font-size: 4vw;
    text-align: justify;
}

.install-timeout-instr {
    margin-bottom: 4vw;
    width: 70vw;
    text-align: justify;
}

.install-in-browser {
    display: block;
    text-align: center;
    font-size: 4vw;
    color: var(--bar);
}

@media (min-width:700px) and (min-aspect-ratio: 1){

    #install-button {
        font-size: 2.5vw;
        padding: 1.5vw;
        width: 20vw;
        margin-bottom: 2vh;
        margin-top: 5vh;
        border-radius: .5vw;
    }

    .install-timeout {
        font-size: 1.95vw;
    }

    .install-timeout-instr {
        margin-bottom: 2vw;
    }
    
    .install-installed {
        font-size: 3vw;
    }

    .install-in-browser {
        font-size: 2vw;
    }

}

@media (min-width:700px) and (min-aspect-ratio: 1.35){

    #install-button {
        font-size: 2vw;
        padding: 1vw;
        width: 15vw;
        border-radius: .5vw;
    }

    .install-timeout {
        font-size: 1.5vw;
    }
    
    .install-installed {
        font-size: 1.5vw;
    }

    .install-in-browser {
        font-size: 1.75vw;
    }

}