.volbio-root {
    box-sizing: border-box;
    padding: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.volbio-view-only-form {
    box-sizing: border-box;
    width: 95%;
    box-sizing: border-box;
    position: relative;
    margin: 2vw 0 0vw 4vw;
}

.volbio-pass {
    position: absolute;
    right: 7.5%;
    bottom: 5%;

    width: 12vw;
    height: 12vw;
    background-color: var(--bar);
    box-sizing: border-box;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-shadow: 0vw .9vw .75vw #aaa;
}

.volbio-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.volbio-action-buttons {
    display: flex;
    align-items:  baseline;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding: 0vw 2vw 2vw 2vw;
    width: 80%;
}

.volbio-headname {
    font-weight: 900;
    font-size: 4.5vw;
}

.volbio-head-name-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 4vw 4vw 2vw 4vw;


}

.volbio-desc {
    text-align: center;
    font-size: 3.5vw;
    box-sizing: border-box;
    padding: 1vw;
}