.drw-main {
    min-height: 50vw;
    background-color: white;
    width: 100vw;

    border-top-left-radius: 2vw;
    border-top-right-radius: 2vw;

    position: fixed;
    bottom: 0vh;
    z-index: 1000;
    animation: drw-appear .4s ease-in-out;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
}

.drw-title {
    font-weight: 900;
}

.drw-header {
    display: flex;
    justify-content: space-between;
    padding: 2vw;
    align-items: center;
}

@keyframes drw-appear {
    from {
        bottom: -50vh;
        opacity: 0;
    }

    to {
        bottom: 0vh;
        opacity: 1;
    }
}