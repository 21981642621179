.home-main {
    margin-top: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2.5vw;
    color: #555;

    width: 100vw;
    height: 90vh;
    text-align: justify; 
}