.imgcropup-crop-head {
    position: absolute;
    z-index: 1;
    color: white;
    
    display: flex;
    width: 100vw;
    justify-content: space-between;
    align-items: center;

    top: 2vh;
    left: 0;

    z-index: 999;
}

.imgcropup-crop-head-left {
    display: flex;
    align-items: center;
    margin-left: 2vw;

}

.imgcropup-label {
    font-size: 4.5vw;
    margin-left: 2vw;
    font-weight: 600;
}

.imgcropup-crop-head-right {
    margin-right: 2vw;
}