.header-user-profile-header {
    display: flex;
    justify-content: start;
    width: 100%;
    padding: 2vw 0 0 5vw;
    box-sizing: border-box;
}

.user-profle-dp {
    font-size: 7.5vw !important;
}

.header-user-menu-icons {
    width: 6vw;
}

.header-user-menu {
    background-color: var(--surface);

    height: 80vh;
    width: 80vw;

    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll;
    color: white;
    position: absolute;
    top: 10vh;
    left: 10vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;

    border-radius: 2vw;
    box-sizing: border-box;

    animation: usermenuzoom .2s ease-in-out;
}

.header-user-menu.close {
    height: 0;
    opacity: 0;
}

.header-dp {
    transition: transform .1s ease-in-out;
}

.header-user-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3vw;
}

.header-username {
    font-size: 4.5vw;
    font-weight: 600;
    margin-bottom: 1vw;
}

.header-userrole {
    font-size: 3.5vw;
}

.header-actions {
    padding: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
}

.header-action-label {
    width: 75vw;
    font-size: 3.5vw;
    opacity: .9;
    padding: 2vw 0 0 2vw;
}

.usermenu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin: 3vw 0 0 0;
    font-size: 3.5vw;
    width: 75vw;
}

.usermenu-label {
    opacity: .9;
}

.usermenu-action-item {
    font-size: 4.5vw;
    user-select: none;

    padding: 0 0 0 2.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header-user-hr {
    height: 0.1vw;
    border: none;
    background-color: antiquewhite;
}

.header-user-profle-dp {
    width: 17vw;
    height: 17vw;
    font-size: 6vw;
    margin-left: 5vw;
}

.header-user-switch-dp {
    width: 10vw;
    height: 10vw;
    font-size: 3.5vw;
}

.header-user-det-dp-cont {
    display: flex;
    flex: 0 0 30%;
    justify-content: center;
}

.header-user-det-name {
    font-weight: bold;
    margin-bottom: .5vw;
    ;
}

.header-user-det {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.header-action {
    display: flex;
    align-items: center;
    cursor: pointer;

    padding: 2vw;
}

.usermenu-switch-action {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 3vw 0 3vw 0
}

.usermenu-switch-logout {
    cursor: pointer;
    margin-right: 2vw;
    width: 20%;
}

.usermenu-switch-details {
    display: flex;
    cursor: pointer;
    width: 80%;
}

.header-action-list {
    box-sizing: border-box;
    padding-left: 2vw;
}

.usermenu-switch-name {
    font-weight: 600;
    font-size: 4vw;
    margin-bottom: .5vw;
}

.usermenu-switch-role {
    font-size: 3.25vw;
}

.usermenu-logout {
    display: flex;
    align-items: center;
    font-size: 4vw;
    margin: 2.5vw 0 2vw 1vw;
}

@keyframes usermenuzoom {
    from {
        transform: scale(0.5);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {

    .header-action-list {
        padding-left: 1vw;
    }


    .header-user-menu {
        left: 30vw;
        width: 35vw;
        border-radius: 0;
    }

    .usermenu-action-item {
        padding: 0 0 0 .75vw;
    }

    .usermenu-header {
        margin: 1vw 0 0 0;
        width: 30vw;
        font-size: 1.65vw;
    }

    .header-user-profile-header {
        padding: .1vw 0 0 2vw;
    }

    .header-username {
        font-size: 1.2vw;
        margin-bottom: .25vw;
    }

    .header-userrole {
        font-size: 1vw;
    }

    .header-user-details {
        margin-left: 1.1vw;
    }

    .header-user-hr {
        width: 30vw;
    }

    .header-action-label {
        width: 30vw;
        font-size: 1vw;
        padding: .3vw 0 0 0.5vw;
    }

    .usermenu-switch-name {
        font-size: 1.1vw;
        margin-bottom: .2vw;
    }

    .usermenu-switch-role {
        font-size: 1vw;
    }

    .usermenu-switch-action {
        margin: 0.75vw 0 0.75vw 0;
    }

    .usermenu-logout {
        font-size: 1.1vw;
        margin: 1vw 0 1vw 1vw;
    }

    .usermenu-label {
        font-size: 1vw;
        margin-bottom: .5vw;
    }

    .usermenu-lo-text {
        margin-left: .2vw;
    }

    .usermenu-switch-logout {
        margin-right: 1vw;
    }

    .header-actions {
        padding: .5vw;
    }

}