.pageload-main {
    display: flex;
    height: 90vh;
    width: 100vw;

    align-items: center;
    justify-content: center;
}

.pageload-block.black {
    background: #282828aa;
    color: white;
}

.pageload-block {
    position: fixed;
    top: 0;
    left: 0;

    z-index: 1111;

    background: #ffffffaa;

    height: 100vh;
    width: 100vw;

    max-height: 100vh;
    max-width: 100vw;

    overflow: hidden;

    display: flex;

    align-items: center;
    justify-content: center;

    animation: pageload-fadein .2s ease-in;
}

@keyframes pageload-fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
