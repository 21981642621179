.tab-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.tab-tabs {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    border-bottom: .5vw #eee solid;
    flex-direction: row;
    flex-wrap: nowrap;
}

.tab-tab {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: center;
    color: #555;
    justify-content: center;
}

.tab-head {
    padding: 2vw;
}

.tab-tab.active {
    background-color: var(--surface);
    color: white;
}

.tab-ch {
    display: flex;
    flex-direction: column;
}
