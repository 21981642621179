.options-main {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    font-size: 4vw;
    padding: 1vw;
    box-sizing: border-box;
    width: 100%;
    box-shadow: -.5vw .5vw 2vw .1vw #aaaaaa7b;
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
    z-index: 998;
    max-height: 50vh;
    overflow-y: scroll;
}

.option-main {
    padding: 2vw;
    cursor: pointer;
}

.option-main.selected {
    background-color: #aaaaaa7b;
}

@media screen and (min-width: 1024px) and (orientation: landscape) {

    .options-main {
        font-size: 1.1vw;
        padding: 0.5vw;
        box-shadow: -.1vw .1vw 1vw .1vw #aaaaaa7b;
        border-bottom-left-radius: .5vw;
        border-bottom-right-radius: .5vw;
        max-height: 50vh;
    }
    
    .option-main {
        padding: 0.5vw;
    }
}