.sevadate-main {
    width: 100vw;
    padding: 2vw 1vw 2vw 1vw;
    box-sizing: border-box;
    border-bottom: .1vw #aaa solid;
}

.sevadate-icon {
    margin: 0 1vw 0 1vw;
}

.sevadate-create-modal {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 2vw 0 18vw 0;
}