.login-container {
  display: flex;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
}

.login-resend-info {
  font-size: 3.75vw;
}

.login-label {
  color: #666;
  font-size: 4vw;
  width: 80vw;
  text-align: center;
  margin-bottom: 3vw;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 35vh;
  align-items: center;
}

.login-status {
  display: flex;
  color: darkslategrey;
  height: 20vw;
  align-items: center;
  width: 70vw;
  justify-content: center;
  text-align: center;
  font-size: 4vw;
}

.login-input {
  width: 77vw;
  height: 10vw;
  margin-bottom: 3vw;
  padding: 1vw;
  border: 1px solid #ccc;
  border-radius: 2vw;
  font-size: 5vw;
  text-align: center;
}

.login-send-button,
.login-reg-button {
  width: 80vw;
  height: 10vw;
  background-color: var(--bar);
  color: #fff;
  font-size: 4vw;
  border: none;
  border-radius: 1vw;
  cursor: pointer;
  margin-bottom: 4vw;
}

.login-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20vh;
}

.login-button:disabled {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

.login-reg-prompt {
  margin: 2vw;
  font-size: 4vw;
}

.login-success {
  display: flex;
  height: 100vh;
  font-size: 5vw;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}

.login-logo-cont {
  display: flex;
  align-items: flex-end;
  margin-top: 10vw;
}

.login-status-cont {
  display: flex;
  height: 5vh;
  flex-direction: column;
  align-items: center;
  margin-top: 2vh;
}

.login-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 2vw;
  color: #777;
  font-size: 3.75vw;
}

.login-reg-link {
  text-decoration: none;
  color: white;
  font-size: 4vw;
}

.login-send-button {
  padding: 2vw;
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  .login-label {
    font-size: 1.25vw;
    margin-bottom: 0.75vw;
  }

  .login-send-button {
    padding: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-input {
    width: 30vw;
    height: 3vw;
    margin-bottom: .5vw;
    padding: .1vw;
    border-radius: .5vw;
    font-size: 1.5vw;
  }

  .login-send-button,
  .login-reg-button {
    width: 30.3vw;
    height: 3vw;
    font-size: 1.1vw;
    border-radius: .5vw;
    margin-bottom: 1vw;
  }

  .login-reg-prompt {
    margin: 0.5vw;
    font-size: 1.25vw;
  }



  .login-logo-cont {
    margin-top: 5vw;
  }

  .login-status {
    font-size: 1vw;
  }

  .login-reg-link {
    font-size: 2vw;
  }

  .login-buttons {
    margin-top: .01vw;
  }

  .login-resend-info {
    font-size: 1.1vw;
  }
}