.texin-root {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    border: none;
    border-radius: 1vw;
    align-items: center;
}

input.texin-field,
textarea.texin-field {
    padding: 1.5vw;
    outline: none;
    border: none;
    border-radius: 1vw;
    font-size: 4vw;
    width: 90%;
}

input.texin-field {
    height: 4.5vw;
}

textarea.texin-field {
    min-height: 20vw;
}

.textin-view-only {
    font-size: 4vw;
    font-weight: 900;
    word-wrap: break-word;
}

input.texin-field.hide {
    width: 0%;
}

.texin-close {
    animation: texin-zoomin-close .3s ease-in-out;
}

.texin-icon {
    margin: 0vw 1vw 0 0;
}

@keyframes texin-zoomin-close {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
    .texin-root {
        border-radius: 0.5vw;
    }

    input.texin-field,
    textarea.texin-field {
        height: 1.5vw;
        padding: .5vw;
        border-radius: 0.5vw;
        font-size: 1.1vw;
        width: 95%;
    }

    textarea.texin-field {
        min-height: 10vw;
    }
}