.check-root {
     display: flex;
     align-items: flex-end;
     user-select: none;
     cursor: pointer;
}

.check-label {
    font-size: 3.75vw;
    margin-left: 1vw;
}