.reg-form {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 2vw 0 18vw 0;
}

.reg-main {
    max-height: 98vh;
    overflow-y: scroll;
}

.reg-inst1 {
    padding: 2.5vw 5vw 2.5vw 5vw;
    font-size: 4vw;
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
    .reg-form {
        margin: 2vw 0 18vw 0;
    }

    .reg-main {
        max-height: 98vh;
        overflow-y: scroll;
    }

    .reg-inst1 {
        padding: 1vw 1vw 0.2vw 1vw;
        font-size: 1.2vw;
    }

    .reg-points {
        line-height: 2vw;
    }
}