.form-main {
    display: flex;
    flex-direction: column;
    width: 85%;
    align-items: center;
}

.form-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 4vw 0 4vw 0;
}

.form-float {
    position: absolute;
    right: 7.5%;
    bottom: 5%;

    width: 12vw;
    height: 12vw;
    background-color: var(--bar);
    box-sizing: border-box;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-shadow: 0vw .9vw .75vw #aaa;
}

.form-item.view-only {
    padding: 1vw 0 3vw 0;
}

.form-section {
    margin-bottom: 5vw;
}

.form-field-group.view-only {
    padding-left: 2vw;
    border-left: 1vw solid #aaa;
}

.form-section.view-only {
    margin-bottom: 4vw;
}

.form-section.hide {
    display: none;
}

.form-section-label {
    font-weight: 900;
    color: #555;
    font-size: 3.75vw;
}

.form-section-label.view-only {
    font-size: 3.5vw;
    margin-bottom: 3vw;
}

.form-navigators {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-evenly;
    margin-bottom: 10vw;
}

.form-navigator {
    cursor: pointer;
    background: var(--bar);
    color: white;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding: 2vw 4vw 2vw 4vw;
    font-size: 4vw;
    margin: 0 2vw 0 2vw;
    border-radius: 1vw;
    border: none;
}

.form-indicators {
    display: flex;
    width: 96%;
    box-sizing: border-box;
    justify-content: space-evenly;
    margin: 2vw 0 2vw 0;
}

.form-head {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 3vw;
}

.form-mand {
    font-size: 3.5vw;
    color: #555;
}

.form-cdef {
    font-size: 3.5vw;
    background-color: #aaa;
    color: #eee;
    padding: 0.75vw 1vw 0.75vw 1vw;
    border-radius: 1vw;
    width: auto;
    text-align: center;
    margin-bottom: 1vw;
    cursor: pointer;
}

.form-navigator.disabled {
    background-color: #888;
}

.form-paginator {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.form-page {
    font-size: 3.5vw;
    color: #888;
}

.form-indicator {
    width: 100%;
    background-color: #aaa;
    height: 2vw;
    border-radius: 100vw;
    box-sizing: border-box;
    margin: 0 .5vw 0 .5vw;
}

.form-indicator.over {
    background-color: var(--surface);
}

@keyframes form-appear-anim {
    from {
        height: 0;
        opacity: 0;
    }

    to {
        height: 100%;
        opacity: 1;
    }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
    .form-cdef {
        font-size: 1.1vw;
        padding: 0.25vw .5vw 0.25vw .5vw;
        border-radius: .2vw;
        margin-bottom: .5vw;
    }

    .form-mand {
        font-size: 1.1vw;
    }

    .form-head {
        margin-bottom: 1vw;
    }

    .form-section {
        margin-bottom: 2vw;
    }

    .form-section-label {
        font-size: 1.1vw;
    }

    .form-item {
        padding: .5vw 0 .5vw 0;
    }

    .form-page {
        font-size: 1.1vw;
    }

    .form-indicators {
        width: 100%;
        margin: 1vw 0 1vw 0;
    }

    .form-indicator {
        height: .75vw;
        border-radius: 100vw;
        margin: 0 .2vw 0 .2vw;
    }

    .form-navigators {
        margin-bottom: 10vw;
    }

    .form-navigator {
        width: 100%;
        padding: 1vw 2vw 1vw 2vw;
        font-size: 1.1vw;
        margin: 0 .1vw 0 .1vw;
        border-radius: .5vw;
        border: none;
    }

}