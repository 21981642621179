.bubble-root {
  position: fixed;
  width: 80vw;
  z-index: 100;
  border-radius: 1vw;
  top: 30vh;
  animation: zoomin .2s ease-in-out;
}

.bubble-body {
  background: white;
  color: black;
  padding: 2vw;
  font-size: 4vw;
  border-radius: 2vw;
}

@keyframes zoomin {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
  
}

@media (min-width:700px) and (min-aspect-ratio: 1){
  .bubble-root {
    width: 40vw;
    border-radius: .5vw;
  }
  
  .bubble-body {
    padding: 1.5vw;
    border-radius: 1vw;
  }
}

@media (min-width:700px) and (min-aspect-ratio: 1.35){
  .bubble-body {
    padding: 1.25vw;
    border-radius: 1vw;
  }
}