.appmenu-slider {
    position: fixed;
    height: 100vh;
    width: 70vw;
    background: var(--surface);

    left: -90vw;
    top: 0vh;
    z-index: 1000;

    max-height: 100vh;
    overflow-y: scroll;

    color: white;
    transition: left .3s ease-in-out;

    padding: 1vw 2vw 0 0;
    box-sizing: border-box;
}

.appmenu-slider.open {
    left: 0vw;
}

.appmenu-label {
    font-size: 3vw;
    opacity: .9;
    padding: 2vw;

}

.appmenu-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2vw;
    font-size: 4vw;
    border-top-right-radius: 100vw;
    border-bottom-right-radius: 100vw;
}

.appmenu-item.selected {
    background: var(--selected);
}

.appmenu-item-name {
    padding-left: 2vw;
}

.appmenu-copy {
    text-align: center;
    margin-top: 1vw;
}

.appmenu-1 {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


@media screen and (min-width: 1024px) and (orientation: landscape) {

    .appmenu-copy {
        margin-top: 0;
    }
    

    .appmenu-slider::-webkit-scrollbar {
        width: .5vw;
    }

    .appmenu-slider::-webkit-scrollbar-track {
        background: var(--surface);
    }

    .appmenu-slider::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 1vw;
    }

    .appmenu-slider::-webkit-scrollbar-thumb:hover {
        background: #eee;
    }

    .appmenu-slider {
        width: 20vw;
    }

    .appmenu-label {
        font-size: 0.9vw;
        padding: .75vw;
    }

    .appmenu-item {
        padding: .5vw;
        font-size: 1.1vw;
    }

    .appmenu-item-name {
        padding-left: .5vw;
    }

}