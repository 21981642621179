.modal-box {
  position: fixed;
  width: 90vw;
  left: 5vw;
  top: 10vh;
  max-height: 85vh;

  background-color: #fff;
  z-index: 999;
  
  display: flex;
  flex-direction: column;

  animation: modal-zoom .1s ease-in-out;
}

.modal-children {
  max-height: 85vh;
  overflow-y: scroll;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--surface);
  color: #fff;
  padding: 2vw 2vw 2vw 2vw;
  font-size: 4vw;
  font-weight: 900;
  box-sizing: border-box;
  align-items: center;
}

@keyframes modal-zoom {
  from {
      transform: scale(0.75);
      opacity: 0;
  }

  to {
      transform: scale(1);
      opacity: 1;
  }
  
}