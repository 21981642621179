.spinner-bg-circle {
  border: 0.75vw solid rgba(128, 128, 128, 0.397);
  border-top: 0.75vw solid var(--bar);
  border-right: 0.75vw solid var(--bar);
  border-bottom: 0.75vw solid var(--bar);
  border-radius: 50%;
  animation: spinner 1s ease-in-out infinite;
  transition: opacity 0.5s;
  width: 4vw;
  height: 4vw;
  opacity: 1;
}

.spinner-show {
  opacity: 1;
}

.spinner-hide {
  opacity: 0;
}

.spinner-load-text {
  color: #555;
  margin-left: 2vw;
  text-align: center;
  font-size: 3.75vw;
}

.spinner-load-text.black {
  color: white;
}

.spinner-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  .spinner-bg-circle {
    border: 0.5vw solid rgba(128, 128, 128, 0.397);
    border-top: 0.5vw solid var(--bar);
    border-right: 0.5vw solid var(--bar);
    border-bottom: 0.5vw solid var(--bar);
    width: 2vw;
    height: 2vw;
  }

  .spinner-load-text {
    font-size: 1.1vw;
    margin-left: 1vw;
  }
}