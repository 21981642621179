#image-picker {
    display: none;
}

.editup-content {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
}

.editup-dp {
}

.editup-dp-holder {
    display: flex;
    align-items: baseline;
    margin: 3vw 0vw 0vw 7vw;
}

.editup-pen {
    background: aliceblue;
    border-radius: 100%;
    padding: 1.5vw;
    box-shadow: 0vw 1vw 3vw 0vw #00000082;
    height: 7vw;
    width: 7vw;
    position: relative;
    left: -14vw;
    top: -2vw;
    cursor: pointer;
}



.editup-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}



.editup-img-sel {
    display: flex;
    width: 70vw;
    padding: 2vw;
    text-align: center;
    cursor: pointer;
    white-space: pre;
    border-radius: 2vw;
    border: 1vw dashed #aaa;
    flex-direction: column;
    align-items: center;

    user-select: none;
}

.editup-or {
    background: #555;
    border-radius: 50%;
    padding: 2vw;
    color: white;
    margin: 1vw 0 1vw 0;
}
.editup-img-sel-cont {
    display: flex;
    width: 100vw;
    height: 50vh;
    justify-content: center;
    align-items: center;
}

.editup-img-sel-icon {
    margin-right: 1vw;
}