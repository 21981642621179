.apphome-send {
    cursor: pointer;
    background: var(--bar);
    color: white;
    width: 80%;
    text-align: center;
    box-sizing: border-box;
    padding: 2vw 4vw 2vw 4vw;
    font-size: 4vw;
    margin: 2vw 2vw 0 2vw;
    border-radius: 1vw;
    border: none;
    text-decoration: none;
}

.apphome-text {
    margin-top: 4vw;
    width: 90vw;
    font-size: 4.5vw;
}

.apphome-main {
    display: flex;
    align-items: center;
}

.apphome-thanks {
    font-size: 4.5vw;
    line-height: 6vw;
}

.apphome-thanks-cont {
    margin-bottom: 4vw;
}

.apphome-wait {
    line-height: 6vw;
    font-size: 4vw;

    margin-bottom: 10vw;
}

.apphome-query {
    font-size: 4vw;
}

@media screen and (min-width: 1024px) and (orientation: landscape) {

    .apphome-send {
        padding: 0.75vw 1vw 0.75vw 1vw;
        font-size: 1.1vw;
        margin: 1vw 1vw 0 1vw;
        border-radius: 0.5vw;
        width: 25%;
    }

    .apphome-text {
        margin-top: 1vw;
    }

    .apphome-thanks {
        font-size: 1.2vw;
        line-height: 1.5vw;
    }

    .apphome-thanks-cont {
        margin-bottom: 1vw;
    }

    .apphome-wait {
        line-height: 1.5vw;
        font-size: 1.1vw;
        width: 25%;
        margin-bottom: 5vw;
    }

    .apphome-query {
        font-size: 1.1vw;
    }

}