.user-root {
    background: white;
    padding: 1vw;
    width: 98vw;
    margin-bottom: .5vw;
    cursor: pointer;
}

.user-left {
    display: flex;
    align-items: center;
}

.user-details {
    padding-left: 1vw;
}

.user-name {
    font-size: 4.5vw;
    font-weight: 600;
}

.user-dp {
    font-size: 4vw;
}