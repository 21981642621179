.volhome-feed {
    background-color: #fff;
    border-radius: 1vw;
    box-shadow: -.5vw 0 1.5vw .1vw #aaa;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 4vw;
    padding: 2vw 2vw 2vw 3.5vw;
    position: relative;
    width: 90vw;
}

.volhome-upcoming {
    font-size: 4vw;
    margin-bottom: 3vw;
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
    .volhome-feed {
        border-radius: 0.5vw;
        box-shadow: -.25vw 0 .5vw .1vw #aaaaaa81;
        margin-bottom: 2vw;
        padding: 1.1vw 1.1vw 1.1vw 1.5vw;
        width: 40vw;
    }

    .volhome-upcoming {
        font-size: 1.1vw;
        margin-bottom: 1vw;
    }
}