.signup-main {
    display: flex;
    flex-direction: column;

    width: 100vw;
    height: 100vh;
    height: 100svh;
    max-height: 100vh;
    max-height: 100svh;
    overflow-y: scroll;
    box-sizing: border-box;
    background-color: #eeeeee85;
}

.signup-logo {
    width: 20vw;
}

.signup-inst-head {
    margin-bottom: 2.5vw;
    box-sizing: border-box;
    font-weight: 900;
    font-size: 3.5vw;
}

.signup-header {
    display: flex;
    flex-direction: row;
    padding: 4vw 0 4vw 4vw;
    align-items: center;
    background-color: var(--surface);
    color: white;
}

.signup-head-title {
    font-size: 5vw;
    font-weight: 900;
}

.signup-head-text {
    width: 80vw;
    display: flex;
    flex-direction: column;
}

.signup-head-desc {
    font-size: 3.5vw;
    margin-top: .5vw;
}

.signup-name {
    background: white;
}

.signup-form-cont {
    display: flex;
    justify-content: center;
}

.signup-dp-main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 5vw;
}

.signup-dp-circle {
    background-color: var(--surface);
    border-radius: 100vw;
    width: 60vw;
    height: 60vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3vh;
    margin-bottom: 3vh;
    cursor: pointer;
}

.signup-dp-plus {
    display: flex;
    margin-bottom: 5vw;
    box-sizing: border-box;
    padding: 5vw;
}

.signup-dp-desc {
    font-size: 4vw;
    font-weight: 100;
    color: #555;
}

.signup-dp-cont {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-dp-inst {
    width: 100%;
    text-align: left;
}

.signup-in {
    color: #555;
    font-size: 4vw;
    padding: 1vw;
}

.signup-over {
    text-decoration: underline;
    color: #555;
    width: 75vw;
    text-align: center;
    margin-top: 5vw;
    font-size: 4vw;
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
    .signup-header {
        padding: 1vw 0 1vw 1vw;
    }

    .signup-head-title {
        font-size: 1.5vw;
    }

    .signup-head-desc {
        font-size: 1vw;
        margin-top: 0.25vw;
    }

    .signup-logo {
        width: 6vw;
    }

    .signup-inst-head {
        font-size: 1.1vw;
        margin-bottom: .4vw
    }

    .signup-dp-main {
        padding: 1vw;
    }

    .signup-in {
        font-size: 1.2vw;
        padding: .1vw;
    }

    .signup-dp-circle {
        width: 20vw;
        height: 20vw;
        margin-top: 2vh;
        margin-bottom: 2vh;
        padding-top: 4vw;
        box-sizing: border-box;
    }

    .signup-dp-desc {
        font-size: 1.2vw;
    }

    .signup-over {
        font-size: 1.2vw;
    }

}