.vol-main {
    display: flex;
    width: 100%;
    justify-content: center;
}

.vol-root {
    display: flex;
    align-items: center;
    background-color: white;
    box-sizing: border-box;
    padding: 2vw 2vw 2vw 4vw;
    border-bottom: .1vw #eee solid;
}

.vol-preacher {
    color: #888;
    font-weight: 200;
    font-size: 3.5vw;
    margin-top: 0.5vw;
}

.vol-chpr {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5vw;
    height: 70vh;
}

.vol-body {
    box-sizing: border-box;
    margin-left: 2vw;
}

.vol-body {
    font-weight: 900;
    font-size: 4vw;
}

.vol-body-holder {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.vol-del {
    background-color: rgb(255, 42, 42);
    color: white;
}