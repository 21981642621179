.header-holder {
    width: 100vw;
}

.header {
    box-sizing: border-box;
    background-color: var(--bar);
    top: 0px;
    left: 0px;
    display: flex;
    width: 100vw;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 1.25vw 1vw 1.25vw 1vw;
}

.header-right {
    display: flex;
    align-items: center;
    width: 18vw;
    justify-content: space-between;
}

.header-left {
    display: flex;
    justify-content: start;
    align-items: center;
}

.header-title {
    color: white;
    padding-left: 1vw;
    font-weight: 100;
    font-size: 4vw;
}

.header-dp.hide {
    display: none;
}

.header-text {
    display: flex;
}

.header-holder {
    user-select: none;
}

.header-logo {
    width: 15vw;
}

@media (min-width:700px) and (min-aspect-ratio: 1) {

    .header {
        padding: .5vw .6vw .5vw .5vw;
    }

    .header-logo {
        width: 7.5vw;
    }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
    .header-title {
        padding-left: .5vw;
        font-weight: 100;
        font-size: 1.2vw;
    }

    .header-right {
        width: 6vw;
    }
}