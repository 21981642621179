.field-main {
    display: flex;
    flex-direction: column;
    position: relative;
}

.field-label {
    font-size: 4vw;
    font-weight: 600;
    color: #666;
    margin: 1vw 0 1vw 0;
    display: inline;
}

.field-label.view-only {
    font-size: 3.5vw;
    font-weight: 600;
    color: #666;
    margin: 1vw 0 1vw 0;
    display: inline;
}

.field-cont {
    border: .1vw solid #aaa;
    border-radius: 1vw;
}

.field-cont.noborder {
    border: none;
}


input.field-field,
textarea.field-field {
    padding: 1.5vw;
    outline: none;
    border: none;
    border-radius: 1vw;
    font-size: 4vw;
    width: 90%;
}

textarea.field-field {
    min-height: 20vw;
}

input.field-field.hide {
    width: 0%;
}

.field-cont.error {
    outline: .2vw solid var(--warning)
}

.field-cont.view-only {
    border: none;
    border-radius: 0;
}

.field-close {
    animation: field-zoomin-close .3s ease-in-out;
}

.field-label-holder {
    margin: 0 0 1vw 0;
}

.field-mand {
    display: inline;
    color: var(--warning);
    margin-left: 0.5vw;
}

.field-error {
    color: var(--warning);
    margin: 1.5vw 0 0 0;
    font-size: 3.5vw;
}

.field-desc {
    font-size: 3.25vw;
    color: #333;
    margin: 0.5vw 0 1.5vw 0;
}

.field-icon {
    margin: 0vw 1vw 0 0;
}

.field-field-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}


@keyframes field-zoomin-close {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
    .field-label {
        font-size: 1.1vw;
    }

    .field-label-holder {
        margin: 0 0 .3vw 0;
    }

    .field-cont {
        border-radius: .5vw;
    }

    .field-cont.error {
        outline: .1vw solid var(--warning)
    }

    .field-error {
        margin: .5vw 0 0 0;
        font-size: 1.1vw;
    }

    .field-desc {
        font-size: 0.95vw;
        margin: 0.1vw 0 .5vw 0;
    }

}