.volseva-reg {
    font-size: 3.5vw;
    padding: 2vw;
    border: none;
    color: white;
    background: var(--bar);
    border-radius: .75vw;
    cursor: pointer;
    margin: 2vw 0 0 0;
    width: 100%;
}

.volseva-name {
    font-size: 4.2vw;
    text-align: left;
}

.volseva-cont {
    border-left: 1vw solid #ccc;
    padding-left: 2vw;
    padding-right: 2vw;
}

.volseva-1 {
    margin: 0vw 0vw 0vw 2vw;
}

.volseva-info {
    margin-top: 1vw;
    font-size: 3.5vw;
}

.volseva-2 {
    align-items: center;
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
    .volseva-reg {
        font-size: 1vw;
        padding: 0.5vw .5vw 0.5vw 0.5vw;
        border-radius: .5vw;
        margin: 1vw 0 .25vw 0;
    }

    .volseva-name {
        font-size: 1.2vw;
    }

    .volseva-1 {
        margin: 0vw 0vw 0vw 1vw;
    }

    .volseva-cont {
        border-left: 0.3vw solid #ccc;
        padding-left: 0.75vw;
        padding-right: 0.75vw;
    }

    .volseva-info {
        font-size: 1vw;
        margin-top: 0.5vw;
    }
}