.sevadates-add {
    background: var(--surface);
    display: flex;
    width: 12vw;
    height: 12vw;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: fixed;
    bottom: 15vw;
    right: 10vw;
    box-shadow: -1px 1px 4px 1px #aaa;
    cursor: pointer;
}

.sevadates-create-modal {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 2vw 0 18vw 0;
}
