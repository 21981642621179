.loader-main {
    width: 60vw;
    height: 2vw;
    background-color: white;
    border-radius: 100vw;
}

.loader-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loader-text {
    width: 60vw;
    text-align: center;
    margin-top: 1.5vw;
    color: #333;
    font-size: 4vw;
}

@media (min-width:700px) and (min-aspect-ratio: 1){

    .loader-main {
        width: 50vw;
        height: 1vw;
    }

    .loader-text {
        width: 50vw;
        margin-top: 1vw;
        font-size: 2vw;
    }
}

@media (min-width:700px) and (min-aspect-ratio: 1.35){

    .loader-main {
        width: 50vw;
        height: 1vw;
    }

    .loader-text {
        width: 50vw;
        margin-top: .75vw;
        font-size: 1.5vw;
    }

}