.cm-root {
    display: flex;
    flex-direction: column;
    position: absolute;
    animation: cm-show-menu .1s ease-out;
    background-color: white;
    min-width: 30vw;
    margin: 0vw 2vw 2vw 2vw;
    z-index: 999;
    box-sizing: border-box;
    padding: 1vw;
    box-shadow: -.2vw .2vw .1vw #aaaaaa4d;
    border-radius: 1vw;
    border: .1vw #aaaaaa66 solid;
    width: max-content;
}

.cm-root.left {
    transform-origin: top right;
    right: 0;
}

.cm-root:focus {
    outline: none;
}

.cm-item {
    box-sizing: border-box;
    padding: 3vw;
    font-size: 4vw;
    border-bottom: .1vw #aaaaaa3a solid;
    cursor: pointer;
}

.cm-item.last {
    border-bottom: none;
}

@keyframes cm-show-menu {
    from {
        transform: scale(.75);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
    .cm-root {
        min-width: 10vw;
        margin: 0vw 1vw 1vw 1vw;
        padding: .75vw;
        box-shadow: -.1vw .1vw .1vw #aaaaaa4d;
        border-radius: .4vw;
    }

    .cm-item {
        padding: .1vw;
        font-size: 1vw;
    }
}